<template>
  <div>
    <DefaultRegister
      :title="$t('register.identityVerification.title')"
      :subTitle="$t('register.identityVerification.subTitle')"
      :narrative="$t('register.identityVerification.narrative')"
    >
      <template #content>
        <router-view :key="$route.fullPath" />
      </template>
    </DefaultRegister>
  </div>
</template>

<script>
import DefaultRegister from '@/components/template/register/Default';

export default {
  name: 'IdentityVerificationIndex',
  components: { DefaultRegister }
};
</script>
